import axios from "axios";
import { MainUrl } from "./ServiceEndpoints";
import { getIsoCode } from "../utils/Utils";

export const getDocuments = async (page, pageSize, filters = {}, token) => {
    let queryString = `page=${page}&pageSize=${pageSize}`;

    // Helper function to append array filters to the query string
    const appendArrayParams = (key, values) => {
        if (Array.isArray(values) && values.length) {
            queryString += `&${key}=${values.join(',')}`;
        }
    };

    // Append filter parameters if they exist
    appendArrayParams('countryCode', filters.countryCode);
    appendArrayParams('documentType', filters.documentType);
    appendArrayParams('state', filters.state);
    appendArrayParams('documentVersion', filters.documentVersion);
    appendArrayParams('yearOfIssue', filters.yearOfIssue);
    appendArrayParams('alias', filters.alias);
    appendArrayParams('statusCode', filters.statusCode);
    appendArrayParams('supportedFrom', filters.supportedFrom);

    // try to convert full country to iso, if not so use as is
    var country = getIsoCode(filters.freeSearch);

    // Append freeSearch if it's not empty
    if (filters.freeSearch) {
        queryString += `&freeSearch=${encodeURIComponent(country != null && country.length > 0 ? country : filters.freeSearch)}`;
    }

    // Encode the entire query string
    const encodedQuery = encodeURIComponent(queryString);

    // Pass the encoded query string as the 'query' parameter
    try {
        const response = await axios.get(`${MainUrl.GetDocuments}?query=${encodedQuery}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching documents:", error);
        throw error;
    }
};

export const track = async (page, pageSize, filters = {}, token, userName, userGroups) => {
    let isinternalUser = userGroups?.some(group => group.toLowerCase().includes("au10tix"));
    
    let queryString = `page=${page}&pageSize=${pageSize}`;

    // Helper function to append array filters to the query string
    const appendArrayParams = (key, values) => {
        if (Array.isArray(values) && values.length) {
            queryString += `&${key}=${values.join(',')}`;
        }
    };

    // Append filter parameters if they exist
    appendArrayParams('countryCode', filters.countryCode);
    appendArrayParams('documentType', filters.documentType);
    appendArrayParams('state', filters.state);
    appendArrayParams('documentVersion', filters.documentVersion);
    appendArrayParams('yearOfIssue', filters.yearOfIssue);
    appendArrayParams('alias', filters.alias);
    appendArrayParams('statusCode', filters.statusCode);
    appendArrayParams('supportedFrom', filters.supportedFrom);

    // try to convert full country to iso, if not so use as is
    var country = getIsoCode(filters.freeSearch);

    // Append freeSearch if it's not empty
    if (filters.freeSearch) {
        queryString += `&freeSearch=${encodeURIComponent(country != null && country.length > 0 ? country : filters.freeSearch)}`;
    }

    // Encode the entire query string
    const encodedQuery = encodeURIComponent(queryString);

    try {
        let url = isinternalUser == null ?  `${MainUrl.Track}?query=${encodedQuery}&userName=${userName}` 
        :`${MainUrl.Track}?query=${encodedQuery}&userName=${userName}&internalUser=${isinternalUser}`;
        // Send POST request with null data and headers passed in the config.
        const response = await axios.post(url,
            null, 
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error tracking click:", error);
        throw error;
    }
};

export const getOptions = async (key, filters = {}, nestedObj, token) => {
    try {
        let str = JSON.stringify(filters);
        const url = `${MainUrl.GetOptions}?key=${key}&nestedObj=${nestedObj}&currentFilters=${encodeURIComponent(str)}`;

        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        const sortedData = (response.data || []).sort();
        return sortedData;
    } catch (error) {
        console.error("Error fetching options:", error);
        throw error;
    }
};