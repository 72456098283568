import React, { useEffect, useState } from "react";
import { Table, Card, message,   Row, Col } from "antd";
import axios from "axios";
import { CommonAdminUrl } from "../../services/ServiceEndpoints";
import moment from "moment";

const ClickStatisticsPage = ({ accessToken }) => {
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [groupedDailyRequests, setGroupedDailyRequests] = useState(null);

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    try {
      setLoading(true); // Ensure loading state is set
  
      const response = await axios.get(
        `${CommonAdminUrl.GetClickStatistic}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setStatistics(response.data);
  
      let res = response.data?.DailyRequestLog
        ? Object.values(
            response.data.DailyRequestLog.reduce((acc, log) => {
              let dateKey = formatDate(log.RequestDate);
  
              if (!acc[dateKey]) {
                acc[dateKey] = {
                  RequestDate: log.RequestDate,
                  TotalRequests: 0,
                  InternalUsers: 0,
                  ExternalUsers: 0,
                };
              }
  
              acc[dateKey].TotalRequests += log.TotalRequests;
              
              if (log.InternalUser === true) {
                acc[dateKey].InternalUsers += log.TotalRequests;
              } else if (log.InternalUser === false) {
                acc[dateKey].ExternalUsers += log.TotalRequests;
              }
              return acc;
            }, {})
          )
        : [];
  
      setGroupedDailyRequests(res); // ✅ Correctly set state
  
    } catch (error) {
      message.error("Failed to fetch statistics");
    } finally {
      setLoading(false); // Ensure loading state is turned off
    }
  };
  
  const formatDate = (date) => (date ? moment(date).format("YYYY-MM-DD HH:mm") : "N/A");

  const tableProps = {
    pagination: { pageSize: 20 },
    loading,
    bordered: true,
    scroll: { y: 400 },
  };


const dailyRequestColumns = [
 { title: "Date", dataIndex: "RequestDate", key: "requestDate", render: formatDate },
 { title: "Total Requests", dataIndex: "TotalRequests", key: "totalRequests" },
 { title: "External Users", dataIndex: "ExternalUsers", key: "externalUsers" },
 { title: "Internal Users", dataIndex: "InternalUsers", key: "internalUsers" },
];

  
  const userStatisticColumns = [
    { title: "User Name", dataIndex: "UserName", key: "userName" },
    { title: "Last Request", dataIndex: "LastRequestAt", key: "lastRequestAt", render: formatDate },
    { title: "Total Requests", dataIndex: "TotalRequests", key: "totalRequests" },
  ];

  const filterStatisticColumns = [
    {
      title: "Country Code",
      dataIndex: "DocumentClassification",
      key: "classificationName",
      render: (classification) => classification ? classification.CountryCode || "-" : "-"
    },
    {
      title: "Document Type",
      dataIndex: "DocumentClassification",
      key: "countryCode",
      render: (classification) => classification ? classification.DocumentType || "-" : "-"
    },
    {
      title: "State",
      dataIndex: "DocumentClassification",
      key: "countryCode",
      render: (classification) => classification ? classification.State || "-" : "-"
    },
    {
      title: "Version",
      dataIndex: "DocumentClassification",
      key: "countryCode",
      render: (classification) => classification ? classification.DocumentVersion || "-" : "-"
    },
    { title: "Last Requested At", dataIndex: "LastRequestedAt", key: "lastRequestedAt", render: formatDate },
    { title: "Total Requests", dataIndex: "TotalRequests", key: "totalRequests" },
  ];

  const documentRequestColumns = [
    { title: "Classification", dataIndex: "ClassificationStr", key: "classificationStr" },
    { title: "LastRequestAt", dataIndex: "LastRequestAt", key: "lastRequestAt", render: formatDate },
    { title: "Total Requests", dataIndex: "TotalRequests", key: "totalRequests" },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={8}>
          <Card title="Daily Requests">
            <Table
              {...tableProps}
              dataSource={groupedDailyRequests || []}
              columns={dailyRequestColumns}
              rowKey={(record) => record.id || record.RequestDate}
            />
          </Card>
        </Col>
        
        <Col xs={24} sm={12} lg={8}>
          <Card title="User Statistics">
            <Table
              {...tableProps}
              dataSource={statistics?.UserStatistic || []}
              columns={userStatisticColumns}
              rowKey={(record) => record.userName || record.id}
            />
          </Card>
        </Col>

      
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
      <Col span={10}>
          <Card title="Document Requests">
            <Table
              {...tableProps}
              dataSource={statistics?.DocumentRequestLog || []}
              columns={documentRequestColumns}
              rowKey={(record) => record.documentId || record.id}
            />
          </Card>
        </Col>
        <Col span={14}>
          <Card title="Filter Statistics">
            <Table
              {...tableProps}
              dataSource={statistics?.FilterStatistic || []}
              columns={filterStatisticColumns}
              rowKey={(record) => record.id || record.LastRequestedAt}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ClickStatisticsPage;