import React, { useEffect, useState } from "react";
import { Card, Empty, Skeleton } from "antd";
import Flag from "react-world-flags";
import { Link } from "react-router-dom";
import "./CustomCard.css";
import { getCountryName } from "../../utils/Utils";
import { CalendarOutlined, GlobalOutlined, HistoryOutlined } from "@ant-design/icons"; // Import Ant Design Icons

const { Meta } = Card;

export const CustomCard = ({ documents, isLoading }) => {
  const [numSkeletons, setNumSkeletons] = useState(20); // Default skeleton count

  const handleClick = () => {
    const currentUrl = window.location.href;
    localStorage.setItem("backUrl", currentUrl);
  };


  // Calculate the number of skeletons based on screen size
  const updateSkeletonCount = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const cardHeight = 220;
    const cardWidth = 300;
    const columns = Math.floor(screenWidth / cardWidth);
    const rows = Math.floor(screenHeight / cardHeight);
    setNumSkeletons(columns * rows + 10);
  };

  useEffect(() => {
    updateSkeletonCount();
    window.addEventListener("resize", updateSkeletonCount);
    return () => window.removeEventListener("resize", updateSkeletonCount);
  }, []);


  const renderSkeletons = () => (
    <div className="card-grid">
      {Array.from({ length: numSkeletons }).map((_, index) => (
        <Card key={index} className="custom-card" loading>
          <Skeleton active title={{ width: "60%" }} paragraph={{ rows: 2 }} />
          <Meta>
            <Skeleton.Image style={{ width: "100%", height: "150px" }} />
          </Meta>
        </Card>
      ))}
    </div>
  );

  const handleImageLoad = (e, isSingleImage) => {
    const img = e.target;

    if (img.naturalWidth > img.naturalHeight) {
      img.style.objectFit = !isSingleImage ? "contain" : "cover";
    } else {
      img.style.objectFit = !isSingleImage ? "cover" : "contain";
    }

  };

  const renderImages = (images, document) => {
    const sortedImages = images.sort((a, b) => {
      if (a.DocumentSide === "Front" && b.DocumentSide !== "Front") return -1;
      if (a.DocumentSide !== "Front" && b.DocumentSide === "Front") return 1;
      return 0;
    });
    const approvedImages = sortedImages.filter((image) => image.ImageStatus === "Approved");
    const isSingleImage = approvedImages.length === 1;

    return approvedImages.map((image, index) => (
      <img
        key={index}
        src={image.uri}
        alt={`Thumbnail ${index + 1}`}
        style={{
          maxWidth: isSingleImage ? "100%" : "50%",
          height: "180px",
          borderRadius: "8px",
          opacity: !isLoading ? 1 : 0,
          transform: !isLoading ? "scale(1)" : "scale(1.1)", // Zoom out effect
          transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
        }}
        onLoad={(e) => handleImageLoad(e, isSingleImage)}
        onError={(e) => {
          e.target.src = "";
        }}
      />

    ));
  };

  const renderCards = () =>
    documents.map((document) => (

      <Card
        key={document.id}
        className="custom-card"
        hoverable
        onClick={handleClick}
      >
        <Link to={`/detail?id=${document.id}`} target="_blank">
          <Meta style={{ justifySelf: "center" }}
            title={
              <div style={{ display: "block", alignItems: "center", gap: "8px", justifyItems: "center" }}>
                <Flag
                  className="flag"
                  code={document.country !== "D" ? document.country : "DEU"}
                  height="30"
                  width="45"
                />
                <div style={{ textWrap: "wrap", alignItems: "center" }} className="title">{prepareTitleDoc(document)}</div>
              </div>
            }
            description={
              <div style={{ justifySelf: "center" }}>
                <div style={{ margin: 4 }} >
                  <CalendarOutlined style={{ marginRight: 8 }} />
                  <strong>Published Date:</strong>{" "}
                  {document.yearOfIssue || "N/A"}
                </div>
                <div style={{ margin: 4 }} >
                  <GlobalOutlined style={{ marginRight: 8 }} />
                  <strong>Language:</strong>{" "}
                  {document.localLanguage
                    ? document.localLanguage.charAt(0).toUpperCase() +
                    document.localLanguage.slice(1)
                    : "N/A"}
                </div>
                <div style={{ margin: 4 }} >
                  <HistoryOutlined style={{ marginRight: 8 }} />
                  <strong>From Version:</strong>{" "}
                  {document.supportedFrom || "N/A"}
                </div>
              </div>
            }
          />
          {document.images?.length > 0 &&
            document.images.some((image) => image.ImageStatus === "Approved") && (
              <div style={{
                display: "flex",
                flexWrap: document.images.filter((image) => image.ImageStatus === "Approved").length > 2 && "wrap",
                justifyContent: "center",
                overflowY: document.images.filter((image) => image.ImageStatus === "Approved").length > 2 && "auto",
                gap: "8px",
              }}
                className="image-container">
                {renderImages(document.images, document)}</div>
            )}
        </Link>
      </Card>
    ));

  return (
    <div className="card-container">
      {isLoading ? (
        renderSkeletons()
      ) : documents?.length ? (
        <div className="card-grid">{renderCards()}</div>
      ) : (
        <Empty description="No results found" />
      )}
    </div>
  );
};

const prepareTitleDoc = (document) => {
  const country = document.country === "D" ? "DEU" : document.country;
  const elements = [
    getCountryName(country) + " (" + country + ")",
    document.type,
    document.documentVersion,
    document.state,
  ];

  return elements
    ?.filter((element) => typeof element === "string" && element !== "")
    .join(" | ");
};

export default CustomCard;
