import React, { useState, useEffect, Suspense } from "react";
import "./Document.css";
import DocumentData from "./DocumentData/DocumentData";
import Profile from "../Document/Profile/Profile";
import { Row, Col } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getCountryName } from "../../utils/Utils";
import Flag from "react-world-flags";

// Lazy load CarouselImages
const CarouselImages = React.lazy(() => import("../Image/CarouselImages"));

export default function Document({ document, title }) {
  const [backImages, setBackImages] = useState([]);
  const [frontImages, setFrontImages] = useState([]);
  const [docTitle, setDocTitle] = useState("");
  const [loadImages, setLoadImages] = useState(false);

  useEffect(() => {
    if (document) {
      const fronts = document.images
        ?.filter((img) => img.documentSide === "Front")
        .map((img) => ({ uri: img.uri, title: img.documentSide }));

      const backs = document.images
        ?.filter((img) => img.documentSide === "Back")
        .map((img) => ({ uri: img.uri, title: img.documentSide }));

      setFrontImages(fronts);
      setBackImages(backs);
      setDocTitle(prepareTitleDoc(document));

      const timer = setTimeout(() => {
        setLoadImages(true);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [document]);

  return (
    <div className="fade-in">
      <Row style={{ marginLeft: "12px", marginBottom: "12px" }} className="slide-in">
        <Col span={8}>
          {frontImages?.length > 0 ? (
            <div style={borderContainerStyle} className="fade-in-delay">
              {loadImages ? (
                <Suspense fallback={<div>Loading images...</div>}>
                  <CarouselImages
                    header={`${frontImages[0].title} side`}
                    image={frontImages.map((img) => img.uri)}
                  />
                </Suspense>
              ) : (
                <div style={borderContainerStyle}>Image carousel will load shortly...</div>
              )}
            </div>
          ) : document?.status === "SinglePage" ||
            document?.status === "MultiPage" ? (
            <div style={borderContainerStyle}>
              {loadImages ? (
                <Suspense fallback={<div>Loading images...</div>}>
                  <CarouselImages
                    header={"First side"}
                    image={frontImages.map((img) => img.uri)}
                  />
                </Suspense>
              ) : (
                <div style={borderContainerStyle}>Image carousel will load shortly...</div>
              )}
            </div>
          ) : null}
        </Col>

        <Col span={16}>
          <div style={borderContainerStyle} className="slide-in-delay">
            <div className="profile-header">
              <h5 style={{ fontSize: "15px" }} className="profile-title">Profile</h5>
              <h4 style={{ fontSize: "18px" }}>{docTitle}</h4>
              <Flag
                style={{ marginInlineStart: "15px" }}
                className="flag"
                code={document.countryCode !== "D" ? document.countryCode : "DEU"}
                height="20"
                width="35"
              />
            </div>
            <Profile document={document} />
          </div>
        </Col>
      </Row>

      <Row style={{ marginLeft: "12px" }} className="fade-in">
        <Col span={8}>
          {backImages?.length > 0 ? (
            <div style={borderContainerStyle}>
              {loadImages ? (
                <Suspense fallback={<div>Loading images...</div>}>
                  <CarouselImages
                    header={`${backImages[0].title} side`}
                    image={backImages.map((img) => img.uri)}
                  />
                </Suspense>
              ) : (
                <div style={borderContainerStyle}>Image carousel will load shortly...</div>
              )}
            </div>
          ) : document.status === "MultiPage" ? (
            <div style={borderContainerStyle}>
              {loadImages ? (
                <Suspense fallback={<div>Loading images...</div>}>
                  <CarouselImages
                    header={"Second side"}
                    image={backImages.map((img) => img.uri)}
                  />
                </Suspense>
              ) : (
                <div style={borderContainerStyle}>Image carousel will load shortly...</div>
              )}
            </div>
          ) : null}
        </Col>
        <Col span={8}>
          <div style={borderContainerStyle}>
            <h5 style={{ fontSize: "15px", marginBottom: "2px", marginLeft: "6px" }}>Data extraction</h5>
            <DocumentData
              data={document.dataFields}
              header={"Data extraction"}
              flag={"dataExtraction"}
              flagForgery={false}
            />
          </div>
        </Col>
        <Col span={8}>
          <div style={borderContainerStyle}>
            <h5 style={{ fontSize: "15px", marginBottom: "2px", marginLeft: "6px" }}>Forgery tests</h5>
            <DocumentData
              data={document.forgeryTests}
              header={"Forgeries"}
              flag={"forgery"}
              flagForgery={true}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

function prepareTitleDoc(document) {
  const elements = [
    getCountryName(document.countryCode),
    document.documentType,
    document.documentVersion,
    document.state,
  ];

  return elements
    ?.filter((element) => typeof element === "string" && element !== "")
    .join(" | ");
}

const borderContainerStyle = {
  border: "1px solid #d9d9d9",
  borderRadius: "8px",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "0px",
  paddingBottom: "30px",
  backgroundColor: "#ffffff",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  margin: "6px",
  height: "100%",
};
