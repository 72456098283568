import React, { useState, useEffect, useRef } from 'react';
import { getDocuments, getOptions, track } from '../../services/documentService.jsx';
import { Layout, Pagination, Alert, Input, Button, Tooltip } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomCard from '../CustomCard/CustomCard.jsx';
import queryString from 'query-string';
import { createErrorElement, formatOptions, getCountryName, prepareDocStatus } from '../../utils/Utils.jsx';
import { CloseOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import FilterSelect from './FilterSelect.jsx';
import { useReload } from '../../utils/ReloadProvider.jsx';
import { useUserContext } from '../../utils/UserProvider.jsx';
import { userGroupsPostFix } from '../../utils/Commons.jsx';
import { debounce } from "lodash";

const { Sider, Content } = Layout;

const MainPage = ({ accessToken }) => {
    const { reloadKey } = useReload();
    const prevReloadKeyRef = useRef(null);
    const currentRequestIdRef = useRef(0);

    const { userGroups, userName } = useUserContext();

    const navigate = useNavigate();
    const location = useLocation();
    const [cache, setCache] = useState({});
    const [documents, setDocuments] = useState([]);
    const [options, setOptions] = useState({});
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingFreeSearch, setIsLoadingFreeSearch] = useState(false);

    const [error, setError] = useState(null);  // Error state
    const [searchValue, setSearchValue] = useState(filters.freeSearch);

    const normalizedSearch = queryString.stringify(queryString.parse(location?.search));

    const handleOpenPage = () => {
        window.open("/moderatePanel", "_blank");
    };

    const handleOpenPageAudit = () => {
        window.open("/moderatePanel/audit", "_blank");
    };

    const handleOpenUsageStatistic = () => {
        window.open("/moderatePanel/usageStatistic", "_blank");
    };

    const handleQueryAndPagination = (parsedQuery) => {
        const initialFilters = getFiltersFromQuery(parsedQuery);
        setFilters(initialFilters);

        setPage(parsedQuery.page ? parseInt(parsedQuery.page, 10) : 1);
        setPageSize(parsedQuery.pageSize ? parseInt(parsedQuery.pageSize, 10) : 20);

        return initialFilters;
    };

    const fetchData = async (filters, parsedQuery, forceReload) => {
        try {
            setError(null);
            fetchOptionsForFilters(filters, options, true);
            await loadDocuments(
                filters,
                parsedQuery.page || 1,
                parsedQuery.pageSize || 20,
                parsedQuery,
                forceReload
            );
        } catch (error) {
            setError('Failed to fetch initial data. Please try again. ' + (error.message || 'Unknown error'));
        }
    };

    useEffect(() => {
        const parsedQuery = queryString.parse(normalizedSearch);
        const filters = handleQueryAndPagination(parsedQuery);
        const wasReloadKeyTriggered = prevReloadKeyRef.current !== reloadKey;
        if(wasReloadKeyTriggered){
            setSearchValue(filters.freeSearch);
        }
        fetchData(filters, parsedQuery, wasReloadKeyTriggered);

        prevReloadKeyRef.current = reloadKey;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [normalizedSearch, reloadKey]);

    const getFiltersFromQuery = (queryParams) => ({
        countryCode: queryParams.countryCode ? queryParams.countryCode.split(',') : [],
        documentType: queryParams.documentType ? queryParams.documentType.split(',') : [],
        state: queryParams.state ? queryParams.state.split(',') : [],
        documentVersion: queryParams.documentVersion ? queryParams.documentVersion.split(',') : [],
        yearOfIssue: queryParams.yearOfIssue ? queryParams.yearOfIssue.split(',') : [],
        alias: queryParams.alias ? queryParams.alias.split(',') : [],
        statusCode: queryParams.statusCode ? queryParams.statusCode.split(',') : [],
        supportedFrom: queryParams.supportedFrom ? queryParams.supportedFrom.split(',') : [],
        freeSearch: queryParams.freeSearch ? queryParams.freeSearch.split(',') : [],
    });

    const buildQueryString = (updatedFilters, pageNum, size) => queryString.stringify({
        ...updatedFilters,
        page: pageNum,
        pageSize: size,
    }, { arrayFormat: 'comma' });

    const handleFilterChange = (key, value) => {
        debouncedHandleFilterChange(key, value);
    };

    const isFirstRender = useRef(true);

   const lastTrackedState = useRef(null);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        const currentState = JSON.stringify({ filters, page, pageSize });

        if (lastTrackedState.current === currentState) {
            return;
        }

        lastTrackedState.current = currentState;

        if (!accessToken || !userName) return;
        track(page, pageSize, filters, accessToken, userName, userGroups)
            .catch((err) => console.error("Error tracking user action:", err));

    }, [filters, page]);


    const debouncedHandleFilterChange = debounce((key, value) => {
        if (key === "freeSearch") {
            setIsLoadingFreeSearch(true);
        }
    
        const updatedFilters = { ...filters, [key]: value };
        setFilters(updatedFilters);
    
        setCache({});
        const newQueryString = buildQueryString(updatedFilters, 1, pageSize);
        navigate({ pathname: location?.pathname, search: `?${newQueryString}` }, { replace: true });
    
        loadDocuments(updatedFilters, 1, pageSize);
    
        if (key === "freeSearch") {
            setIsLoadingFreeSearch(false);
            return;
        }
        fetchOptionsForFilters(updatedFilters, key, false, options);
    }, 300); 

    const fetchOptionsForFiltersFirstInit = async (updatedFilters) => {
        try {
            setError(null);
            const [countries, documentTypes, states, documentVersions, yearsOfIssue, aliases,
                statusCodes, supportedFrom] = await Promise.all([
                    getOptions('countryCode', updatedFilters, "no", accessToken),
                    getOptions('documentType', updatedFilters, "no", accessToken),
                    getOptions('state', updatedFilters, "no", accessToken),
                    getOptions('documentVersion', updatedFilters, "no", accessToken),
                    getOptions('yearOfIssue', updatedFilters, "attributes", accessToken),
                    getOptions('alias', updatedFilters, "attributes", accessToken),
                    getOptions('statusCode', updatedFilters, "no", accessToken),
                    getOptions('supportedFrom', updatedFilters, "attributes", accessToken),
                ]);

            const documentVerRefacted = documentVersions?.map(v =>
                typeof v === 'string' ? v.replace(/,/g, "_") : v
            );

            setOptions({
                countries: countries.map(option => ({
                    value: option, // Use ISO code as the value
                    label: `${option} (${getCountryName(option)})` // Display both ISO and full name
                })),
                documentTypes: formatOptions(documentTypes),
                states: formatOptions(states),
                documentVersions: formatOptions(documentVerRefacted),
                yearsOfIssue: formatOptions(yearsOfIssue),
                aliases: formatOptions(aliases),
                statusCodes: formatOptions(statusCodes),
                supportedFrom: formatOptions(supportedFrom),
            });
        } catch (error) {
            setError(createErrorElement(error, "fetchOptionsForFilters"));
        }
    };

    const fetchOptionsForFilters = async (updatedFilters, key, firstInitialization, currentOptions) => {
        try {
            if (firstInitialization) {
                fetchOptionsForFiltersFirstInit(updatedFilters);
            } else {
                const promises = [];

                if (key !== 'countryCode' || updatedFilters?.countryCode?.length === 0) {
                    promises.push(getOptions('countryCode', updatedFilters, "no", accessToken));
                } else {
                    promises.push(currentOptions.countries);
                }

                if (key !== 'documentType' || updatedFilters?.documentType?.length === 0) {
                    promises.push(getOptions('documentType', updatedFilters, "no", accessToken));
                } else {
                    promises.push(currentOptions.documentTypes);
                }

                if (key !== 'state' || updatedFilters?.state?.length === 0) {
                    promises.push(getOptions('state', updatedFilters, "no", accessToken));
                } else {
                    promises.push(currentOptions.states);
                }

                if (key !== 'documentVersion' || updatedFilters?.documentVersion?.length === 0) {
                    promises.push(getOptions('documentVersion', updatedFilters, "no", accessToken));
                } else {
                    promises.push(currentOptions.documentVersions);
                }

                if (key !== 'yearOfIssue' || updatedFilters?.yearOfIssue?.length === 0) {
                    promises.push(getOptions('yearOfIssue', updatedFilters, "attributes", accessToken));
                } else {
                    promises.push(currentOptions.yearsOfIssue);
                }

                if (key !== 'alias' || updatedFilters?.alias?.length === 0) {
                    promises.push(getOptions('alias', updatedFilters, "attributes", accessToken));
                } else {
                    promises.push(currentOptions.aliases);
                }

                if (key !== 'statusCode' || updatedFilters?.statusCode?.length === 0) {
                    promises.push(getOptions('statusCode', updatedFilters, "no", accessToken));
                } else {
                    promises.push(currentOptions.statusCode);
                }

                if (key !== 'supportedFrom' || updatedFilters?.supportedFrom?.length === 0) {
                    promises.push(getOptions('supportedFrom', updatedFilters, "attributes", accessToken));
                } else {
                    promises.push(currentOptions.supportedFrom);
                }

                const [
                    countries, documentTypes, states, documentVersions,
                    yearsOfIssue, aliases, statusCodes, supportedFrom
                ] = await Promise.all(promises);

                const isFormatted = (options) => Array.isArray(options)
                    && options?.length > 0
                    && options[0] !== null
                    && typeof options[0] === 'object'
                    && options[0].hasOwnProperty('label')
                    && options[0].hasOwnProperty('value');

                const documentVerRefacted = documentVersions?.map(v =>
                    typeof v === 'string' ? v.replace(/,/g, "_") : v
                );

                setOptions({
                    countries: !isFormatted(countries) ? countries.map(option => ({
                        value: option,
                        label: `${option} (${getCountryName(option)})`
                    })) : currentOptions?.countries,
                    documentTypes: !isFormatted(documentTypes) ? formatOptions(documentTypes) : currentOptions?.documentTypes,
                    states: !isFormatted(states) ? formatOptions(states) : currentOptions?.states,
                    documentVersions: !isFormatted(documentVerRefacted) ? formatOptions(documentVerRefacted) : currentOptions?.documentVersions,
                    yearsOfIssue: !isFormatted(yearsOfIssue) ? formatOptions(yearsOfIssue) : currentOptions?.yearsOfIssue,
                    aliases: !isFormatted(aliases) ? formatOptions(aliases) : currentOptions?.aliases,
                    statusCode: !isFormatted(statusCodes) ? formatOptions(statusCodes) : currentOptions?.statusCodes,
                    supportedFrom: !isFormatted(supportedFrom) ? formatOptions(supportedFrom) : currentOptions?.supportedFrom,
                });
            }
            setError(null);
        } catch (error) {
            setError(createErrorElement(error, "fetchOptionsForFilters"));
        }
    };
    
    const loadDocuments = async (
        filterData = filters,
        pageNum = page,
        size = pageSize,
        queryStringified,
        forceReload = false
    ) => {
        const currentRequestId = ++currentRequestIdRef.current; // Track the current request
        try {
            setIsLoading(true);
            setError(null);
    
            // Use cached documents if available and forceReload is false
            if (!forceReload && cache[pageNum]) {
                const cachedDocuments = [...cache[pageNum].documents].sort((a, b) =>
                    a.country?.localeCompare(b.country)
                );
                setDocuments(cachedDocuments);
                setTotalDocuments(cache[pageNum].totalCount);
                setIsLoading(false);
                return;
            }
    
            const response = await getDocuments(pageNum, size, filterData, accessToken, queryStringified);
            if (currentRequestId !== currentRequestIdRef.current) return; // Ignore outdated request
            const formattedDocuments = await formatDocuments(response || []);

            setDocuments(formattedDocuments);
            setTotalDocuments(response?.TotalCount || 0);
            setCache((prevCache) => ({
                ...prevCache,
                [pageNum]: { documents: formattedDocuments, totalCount: response?.TotalCount || 0 },
            }));
    
            window.scrollTo({ top: 0, behavior: 'smooth' });

            setIsLoading(false);
        } catch (error) {
            if (currentRequestId !== currentRequestIdRef.current) return; // Ignore outdated request
            const errorMessage = error?.message || 'Unknown error occurred while loading documents.';
            setError(createErrorElement(errorMessage, "loadDocuments"));
            console.error("Error in loadDocuments:", error);
            setIsLoading(false);
        } 
    };
    
    const clearFilters = async () => {
        setFilters({});
        setCache({});

        const params = new URLSearchParams();

        params.set('page', '1');
        params.set('pageSize', pageSize.toString());

        navigate(`${location?.pathname}?${params.toString()}`, { replace: true });

        setSearchValue(null);
    };

    const formatDocuments = async (data) => {
        if (!data || !data.Items || data.Items.length === 0) return [];

        try {
            const formatted = data.Items.map((item) => {
                const doc = JSON.parse(item.Data);
                const metadata = item.Metadata;

                if (Array.isArray(doc.Images) && doc.Images.length > 0 && Array.isArray(metadata)) {
                    doc.Images.forEach((image) => {
                        if (image?.DocumentSide) {
                            const key = image.DocumentSide.trim().charAt(0).toUpperCase() + image.DocumentSide.trim().slice(1).toLowerCase();
                            const metaEntry = metadata.find((meta) => meta.Item1 === key);
                            image.uri = metaEntry ? metaEntry.Item2 : null;
                        }
                    });
                }

                // Return the formatted document object
                return {
                    country: doc.CountryCode,
                    type: doc.DocumentType,
                    documentVersion: doc.DocumentVersion,
                    state: doc.State,
                    supportedPages: prepareDocStatus(doc.Status),
                    id: doc.Id,
                    images: doc.Images,
                    localLanguage: doc?.Attributes?.LocalLanguage,
                    yearOfIssue: doc?.Attributes?.YearOfIssue || null,
                    supportedFrom: doc.Attributes?.SupportedFrom
                };
            });

            return formatted.sort((a, b) => (a.country || "").localeCompare(b.country || ""));
        } catch (error) {
            console.error("Error formatting documents", error);
            return [];
        }
    };

    const handlePageChange = async (newPage, newPageSize) => {
        setPage(newPage);
        setPageSize(newPageSize);

        const queryStringified = buildQueryString(filters, newPage, newPageSize);
        navigate({ pathname: location?.pathname, search: `?${queryStringified}` }, { replace: true });

        await loadDocuments(filters, newPage, newPageSize, queryStringified);
    };

    const handleFreeSearchChange = (e) => {
        setSearchValue(e.target.value);
        if (e.target.value == null || e.target.value === "") {
            clearFilters();
        }
    };

    const onPageSizeChange = async (current, newPageSize) => {
        setPageSize(newPageSize);
        setPage(1);

        const queryStringified = buildQueryString(filters, 1, newPageSize);
        navigate({ pathname: location?.pathname, search: `?${queryStringified}` }, { replace: true });

        await loadDocuments(filters, 1, newPageSize, queryStringified);
    };

    return (
        <Layout>
            {isLoading && <div className="loading">Loading&#8230;</div>}
            <Sider width="320">
                <hr style={{ border: "none", borderTop: "1px solid #e0e0e0", margin: "0 16px" }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '6px 16px' }}>
                    <Button
                        type="link"
                        icon={<CloseOutlined />}
                        onClick={clearFilters}
                        style={{
                            padding: 0,
                            fontSize: '14px',
                            color: "rgb(49, 49, 49)",
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        Clear all filters
                    </Button>
                </div>
                <hr style={{ border: "none", borderTop: "1px solid #e0e0e0", margin: "0 16px" }} />

                <FilterSelect
                    options={options.countries}
                    placeholder="Select country"
                    value={filters.countryCode}
                    onChange={(val) => handleFilterChange('countryCode', val)}
                />
                <FilterSelect
                    options={options.documentTypes}
                    placeholder="Select document type"
                    value={filters.documentType}
                    onChange={(val) => handleFilterChange('documentType', val)}
                />
                <FilterSelect
                    options={options.states}
                    placeholder="Select state"
                    value={filters.state}
                    onChange={(val) => handleFilterChange('state', val)}
                />
                <FilterSelect
                    options={options.documentVersions}
                    placeholder="Select version"
                    value={filters.documentVersion}
                    onChange={(val) => handleFilterChange('documentVersion', val)}
                />
                <FilterSelect
                    options={options.yearsOfIssue}
                    placeholder="Select year of issue"
                    value={filters.yearOfIssue}
                    onChange={(val) => handleFilterChange('yearOfIssue', val)}
                />
                <FilterSelect
                    options={options.aliases}
                    placeholder="Select alias"
                    value={filters.alias}
                    onChange={(val) => handleFilterChange('alias', val)}
                />
                <FilterSelect
                    options={options.statusCodes}
                    placeholder="Select single/couple"
                    value={filters.statusCode}
                    onChange={(val) => handleFilterChange('statusCode', val)}
                />
                <FilterSelect
                    options={options.supportedFrom}
                    placeholder="Select Sdr version"
                    value={filters.supportedFrom}
                    onChange={(val) => handleFilterChange('supportedFrom', val)}
                />
                <hr style={{ width: "auto", border: "none", borderTop: "0.5px dotted #000", margin: "18px" }} />

                <div style={{ margin: '16px', display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Search documents by terms.
                 Use commas to separate terms (e.g., '2019,USA') 
                 and '-' to exclude (e.g., '2019,-english').">
                        <Input
                            value={searchValue != null ? searchValue : filters?.freeSearch}
                            onChange={handleFreeSearchChange}
                            placeholder="Free search..."
                            style={{
                                padding: '10px',
                                height: '35px',
                                flexGrow: 1,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                            }}
                        />
                    </Tooltip>

                    <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        onClick={(e) => handleFilterChange('freeSearch', [searchValue])}
                        style={{
                            height: '35px',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '45px',
                        }}
                    />
                </div>
                {isLoadingFreeSearch ? (
                    <p style={{ color: 'gray', fontSize: '14px', margin: '16px' }}>
                        Please wait, this search may take a little longer than usual. <LoadingOutlined />
                    </p>
                ) : null}


                <hr style={{ width: "auto", border: "none", borderTop: "0.5px dotted #000", margin: "18px" }} />

                <div style={{ textAlign: 'left', margin: 16, position: 'sticky', bottom: 0, backgroundColor: 'white', padding: '8px', boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)' }}>
                    <p
                        style={{
                            fontSize: '14px',
                            borderRadius: '8px',
                            marginLeft: '2px'
                        }}
                    >
                        Found documents:  {totalDocuments}
                    </p>
                </div>
                <hr style={{ width: "auto", border: "none", borderTop: "0.5px dotted #000", margin: "18px" }} />
                {userGroups?.some((group) =>
                    group.includes(userGroupsPostFix.Moderator))
                    ? (
                        <div style={{
                            textAlign: 'left',
                            margin: 16,
                            bottom: 0,
                            width: "auto",
                            left: 0,
                            padding: '8px',
                            boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)'
                        }}>
                           
                            <Button type="primary" style={{ fontWeight: 700, width: "100%" }}
                                key="moderator-console" onClick={handleOpenPage}>
                                Moderator Console
                            </Button>
                            <Button type="primary" style={{ fontWeight: 700, width: "100%", marginTop: "10px" }}
                                key="moderator-usage" onClick={handleOpenUsageStatistic}>
                                Usage Statistic
                            </Button>
                            <Button type="primary" style={{ fontWeight: 700, width: "100%", marginTop: "10px" }}
                                key="moderator-audit" onClick={handleOpenPageAudit}>
                                Data Transfer Audit
                            </Button>
                        </div>
                    ) : null}

            </Sider>
            <Content style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' }}>
                {error && <div> <Alert message={error} type="error" showIcon style={{ margin: "15px" }} /></div>}

                <div style={{ flex: 1, overflowY: 'auto' }}>
                    <CustomCard documents={documents} isLoading={isLoading} />
                </div>

                <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', padding: '16px', boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)' }}>

                    <Pagination
                        current={page}
                        pageSize={pageSize}
                        pageSizeOptions={['10', '20', '50']}
                        total={totalDocuments}
                        onChange={handlePageChange}
                        onShowSizeChange={onPageSizeChange}
                        showSizeChanger
                    />
                </div>
            </Content>
        </Layout>
    );
};


export default MainPage;