import React, { useEffect, useState } from "react";
import { MainUrl } from "../../../services/ServiceEndpoints";
import Document from "../Document";
import axios from "axios";
import "react-awesome-slider/dist/styles.css";
import BackButton from "../../Elements/Back";
import { Divider, Skeleton, Alert } from "antd"; // Import Alert
import Loading from "../../Elements/Loading";
import { useUserContext } from "../../../utils/UserProvider";

const DetailsDocument = ({ accessToken }) => {
    const [document, setDocument] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); // New state for error handling
    const { userGroups, userName } = useUserContext();

    useEffect(() => {
        if (accessToken && userGroups) {
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id');

            handleTrack(id).catch((error) =>
                console.error("Error tracking click (fire-and-forget):", error)
            );
        }
    }, [userGroups, accessToken, userName]);

    useEffect(() => {
        const fetchDocumentData = async (id) => {
            console.log("fetchDocumentData");
            try {
                if (!id) {
                    setError("Document ID is missing from the URL.");
                    setLoading(false);
                    return;
                }

                const documentResponse = await axios.get(
                    `${MainUrl.GetDocumentDataById}?id=${id}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    }
                );

                const documentData = documentResponse.data;

                if (!documentData || Object.keys(documentData).length === 0) {
                    setError("No document found.");
                    setLoading(false);
                    return;
                }

                // Filter Approved Images
                const approvedImages = documentData.images?.filter(x => x.imageStatus === 'Approved') || [];
                documentData.images = approvedImages;

                if (approvedImages.length > 0) {
                    const urisResponse = await axios.get(
                        `${MainUrl.GetSasUrlByDocumentId}?id=${id}`,
                        {
                            headers: { Authorization: `Bearer ${accessToken}` },
                        }
                    );

                    const uris = urisResponse.data;

                    if (Array.isArray(uris)) {
                        approvedImages.forEach((image) => {
                            const uriEntry = uris.find((uri) => uri.Item1 === image.documentSide);
                            if (uriEntry && image.documentSide) {
                                image.uri = uriEntry.Item2;
                            } else {
                                console.warn(`No matching URI found for image.documentSide: ${image.documentSide}`);
                            }
                        });
                    } else {
                        console.error("URIs response is not a valid array:", uris);
                    }
                }

                setDocument(documentData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching document data:", error.message || error);
                setError("Failed to fetch document. Please try again later.");
                setLoading(false);
            }
        };

        if (accessToken) {
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id');
            fetchDocumentData(id);
        }
    }, [accessToken]);

    const handleTrack = async (id) => {
        try {
            if (userGroups?.length > 0 && id && userName != null) {
                let isInternalUser = userGroups.some(group => group.toLowerCase().includes("au10tix"));
                let url = isInternalUser
                    ? `${MainUrl.Track}?id=${id}&userName=${userName}&internalUser=${isInternalUser}`
                    : `${MainUrl.Track}?id=${id}`;

                const response = await axios.post(url, null, {
                    headers: { Authorization: `Bearer ${accessToken}` }
                });
                return response.data;
            }
        } catch (error) {
            console.error("Error tracking click:", error);
            return;
        }
    };

    return (
        <div>
            {loading ? (
                <div>
                    <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "1rem", height: "550px" }}>
                    Loading...
                        <Skeleton.Input style={{ width: "80%", maxWidth: "100%" }} active />
                        <Skeleton active title={false} paragraph={{ rows: 10, width: "100%" }} style={{ maxWidth: "100%" }} />
                    </div>
                </div>
            ) : error ? (
                <div style={{ padding: "20px" }}>
                    <Alert message="Error" description={error} type="error" showIcon />
                    <div style={{ marginTop: "10px" }}>
                        <BackButton />
                    </div>
                </div>
            ) : document ? (
                <div>
                    <div style={{ marginTop: "0.7%", marginRight: "1%" }}>
                        <BackButton />
                    </div>
                    <Divider />
                    <div style={{ marginRight: "1%", marginTop: "-1%", marginBottom:"2%" }}>
                        <Document document={document} />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default DetailsDocument;
