//export const url = "https://weu-api.au10tixservicesdev.com";
export const url = "https://weu-cm-apim-dev.azure-api.net";
export const baseURL = `${url}/docupedia/`;
export const baseAdminURL = baseURL +  'admin';
export const baseMainURL = baseURL +  'main';

export const CommonAdminUrl = {
    GetImagesByStatus: baseAdminURL + '/getImagesByStatus',
    GetAuditData: baseAdminURL + '/getAuditData',
    UpdateImageStatus: baseAdminURL + '/updateImageStatus',
    GetClickStatistic: baseAdminURL + '/getClickStatistic',
}

export const MainUrl = {
    GetDocuments: baseMainURL + '/getDocuments',
    GetOptions: baseMainURL + '/getOptions',
    GetDocumentTypes: baseMainURL + '/getDocumentTypes',
    GetSasUrlByDocumentId: baseMainURL + '/getSasUrlByDocumentId',
    GetDocumentsSasUrl: baseMainURL + '/getDocumentsSasUrl',
    GetSasUrlsByDocumentIds: baseMainURL + '/getSasUrlsByDocumentIds',
    GetDocumentDataById: baseMainURL + '/getDocumentDataById',
    GetDocumentsDataForCsv: baseMainURL + '/getDocumentsDataForCsv',
    Track: baseMainURL + '/track',
}