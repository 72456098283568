import React from "react";
import "./Profile.css";

function Profile({ document }) {
  const attributes = document.attributes;

  const labelsForFirstColumn = {
    "Country code:": document.countryCode,
    "Document type:": document.documentType,
    "Document version:": document.documentVersion,
    "Page:": document.attributes?.Page,
    "Supported from:": attributes?.supportedFrom,
    "Alias:": attributes?.alias,
    "Sides:": document.status,
    "Support Status:": document.SupportStatus,
    "Real ID:": attributes?.RealId,
    "Signature status:": attributes?.SignatureStatus,
  };

  const labelsForSecondColumn = {
    "Issue year:": attributes?.yearOfIssue,
    "Language:": attributes?.LocalLanguage,
    "Size:": attributes?.size,
    "Has MRZ:": attributes?.mrz,
    "Has Chip:": attributes?.SignatureStatus,
    "Barcode status:": attributes?.BarcodeStatus,
    "Barcode type:": attributes?.BarcodeType,
    "Digital Candidate:": attributes?.DigitalCandidate,
    "Second Side Significant:": attributes?.SecondSideSignificant,
  };

  const prepareList = (key, value) => (
    <div key={key} className="profile-item">
      <div className="profile-label">{key}</div>
      <div className="profile-value">{value || "Unknown"}</div>
    </div>
  );

  return (
    <div className="profile-container">
      <div className="profile-column">
        {Object.entries(labelsForFirstColumn).map(([key, value]) =>
          prepareList(key, value)
        )}
      </div>

      <div className="profile-column">
        {Object.entries(labelsForSecondColumn).map(([key, value]) =>
          prepareList(key, value)
        )}
      </div>
    </div>
  );
}

export default Profile;
